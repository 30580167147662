import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { DateTime } from 'luxon';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';

import getNodeDescriptionCombinations from '@/graphql/cleanData/queries/getNodeDescriptionCombinations.graphql';
import getCleanTSDataTimeRangeV2 from '@/graphql/cleanData/queries/getCleanTSDataTimeRangeV2.graphql';
import cleanDataApolloClient from '@/lib/appsync/cleanData';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqSignalsModule',
  store,
})
class TasqSignalsModule extends VuexModule {
  availableSignals = getConfigEnv('SIGNALS_ARRAY');

  currentSignals: any = [];

  @Mutation
  resetSignals(): void {
    this.currentSignals = [];
  }

  @Mutation
  addSignal(signal): void {
    const currentSignalsNames = this.currentSignals.map((s) => s.name);
    if (currentSignalsNames.includes(signal.name)) return;
    this.currentSignals.push(signal);
  }

  @Action
  async getSignalsForTasq(daysLength = 14): Promise<void> {
    if (!tasqsListModule.activeTasq?.wellName) return;
    this.resetSignals();

    const {
      data: {
        get_clean_ts_data_node_description_combinations_v2: nodeData,
      },
    } = await cleanDataApolloClient.query({
      query: getNodeDescriptionCombinations,
      variables: {
        operator: getConfigEnv('OPERATOR_PASCALED'),
        nodeid: tasqsListModule.activeTasq?.wellName,
        filter: 'tasq_page',
      },
    });
    const backendSignals = jsonParse(nodeData)?.Description || [];
    const signalsToFetch = this.availableSignals.filter(
      (s) => backendSignals.includes(s.slug),
    ).map((s) => s.slug);

    const fromTime = DateTime.local().minus({ days: daysLength }).toISO();
    const toTime = DateTime.local().toISO();

    signalsToFetch.map(async (signal, idx) => {
      const {
        data:
          {
            get_clean_ts_data_time_range_v2: {
              dataset,
              description,
              units,
            },
          },
      } = await cleanDataApolloClient.query({
        query: getCleanTSDataTimeRangeV2,
        variables: {
          nodeid: tasqsListModule.activeTasq?.wellName,
          description: [signal],
          from_time: fromTime,
          to_time: toTime,
          operator: getConfigEnv('OPERATOR_PASCALED'),
        },
      });
      const parsedDataset = JSON.parse(dataset);
      this.addSignal({
        id: idx,
        name: description[0],
        dataset: parsedDataset.Value[0],
        time: parsedDataset.time[0],
        unit: units[0],
      });
    });
  }
}

export default getModule(TasqSignalsModule);
