import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
} from 'vuex-module-decorators';
import { getConfigEnv } from '@/utils/helpers';
import postNewTasq from '@/graphql/predictions/mutations/postNewTasq.graphql';
import updateStatus from '@/graphql/predictions/mutations/updateStatus.graphql';
import reassignTasq from '@/graphql/workflow/mutations/reassignTasq.graphql';
import predictionsApolloClient from '@/lib/appsync/workflow';
import workflowApolloClient from '@/lib/appsync/workflow';
import tasqsListModule from '@/store/modules/tasqsListModule';


@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqActionsModule',
  store,
})
class TasqActionsModule extends VuexModule {
  @Action
  async addTasq({
    person,
    well,
    jobType,
    frequency,
    delayedDays,
    stateChangeDate,
    stateChangeDateEnd,
  }) {
    try {
      await predictionsApolloClient.mutate({
        mutation: postNewTasq,
        variables: {
          input: {
            assigned_to_user: person,
            node_id: well,
            prediction_type: jobType,
            ...stateChangeDate && {
              state_change_date: new Date(stateChangeDate).toISOString().slice(0, -5),
            },
            ...stateChangeDateEnd && {
              state_change_date_end: new Date(stateChangeDateEnd).toISOString().slice(0, -5),
            },
            ...frequency === 'Reoccurance' && {
              recurring_start_date: new Date().toISOString(),
              recurring_end_date: new Date(
                new Date().getTime() + 86400000 * delayedDays,
              ).toISOString(),
            },
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      });
    } catch (e) {
      // showApplicationError('Error while leaving feedback');
      // router.push({ name: 'Tasqs' });
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async reassignTasq({
   person,
   comment,
 }) {
    await workflowApolloClient.mutate({
      mutation: reassignTasq,
      variables: {
        input: {
          id: tasqsListModule.activeTasq?.id,
          username: person,
          comment: comment,
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }

  @Action
  async rejectTasq({
   reason,
 }) {
    await predictionsApolloClient.mutate({
      mutation: updateStatus,
      variables: {
        input: {
          id: tasqsListModule.activeTasq?.id,
          rejected: {
            Status: true,
            reason,
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }


  @Action
  async updateTasqStatus({
   reason,
   date,
 }) {
    await predictionsApolloClient.mutate({
      mutation: updateStatus,
      variables: {
        input: {
          PredictionID: tasqsListModule.activeTasq?.id,
        //   Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          Snoozed: {
            Status: true,
            UntilDate: new Date(date).toISOString().slice(0, -5),
            WaitUntilReason: reason,
            // Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      },
    });
  }
}

export default getModule(TasqActionsModule);
