




















































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getComponent } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
import DataLoading from '@/lib/mixins/dataLoading';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import { ALL_TASQS_LIST_ITEM, SHOW_ALERT, TASQ_OFF_TARGET_TYPE } from '@/lib/constants';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import workflowModule from '@/store/modules/workflowModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqSignal: () => getComponent('tasqs/TasqSignal'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    TasqFeedback: () => getComponent('tasqs/TasqFeedback'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
  },
})
export default class Tasqs extends mixins(DataLoading) {
  tasqOptionsOpen = false;

  chartsLoading = false;

  signalChartTime = 14;

  loadingTasqs = false;

  showActionDialog: boolean = false;

  action: any = {
    type: '',
    title: '',
    confirmText: '',
    reason: [],
    person: '',
    comment: '',
    date: '',
    well: '',
    jobType: '',
    frequency: '',
    delayedDays: 0,
  };

  chartTimes: any[] = this.$getConst('CHART_TIMES');

  get isProductionDataLoaded() {
    return tasqProductionDataChartModule.productionData.loaded;
  }

  get currentSignals(): any {
    if (this.chartsLoading) {
      return [];
    }
    return tasqSignalsModule.currentSignals;
  }

  get activeTasq() {
    return tasqsListModule.activeTasq;
  }

  get reassignUsers() {
    return accountModule.reassignmentList.map(u => ({
      text: u.name,
      value: u.email,
    }));
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  async created() {
    this.loadingTasqs = true;
    await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    if (this.$route.params.id && !this.currentSignals.length) {
      await this.fetchTasq();
    }
    this.loadingTasqs = false;
    this.stopDataLoading();
    await assetsModule.getEnabledWells();
  }

  beforeDestroy() {
    tasqsListModule.setActiveTasq('');
  }

  prepareAction(action) {
    this.showActionDialog = true;
    this.action = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      well: '',
      jobType: '',
      frequency: '',
      delayedDays: 0,
    };
    switch (action) {
      case ('reject'):
        this.action.type = action;
        this.action.title = `Are you sure you want to reject ${this.activeTasq?.wellName}?`;
        this.action.confirmText = 'Yes, reject it';
        this.$dialog.show('actionModal');
        break;
      case ('reassign'):
        this.action.type = action;
        this.action.title = `Select the user to reassign ${this.activeTasq?.wellName }:`;
        this.action.confirmText = 'Reassign tasq';
        this.$dialog.show('actionModal');
        break;
      case ('wait'):
        this.action.type = action;
        this.action.title = `Please select status for ${this.activeTasq?.wellName }:`;
        this.action.confirmText = 'Update';
        this.$dialog.show('actionModal');
        break;
      case ('add'):
        this.action.type = action;
        this.action.title = '';
        this.action.confirmText = 'Add tasq';
        this.action.person = accountModule.user.email;
        this.$dialog.show('actionModal');
        break;
      default:
        break;
    }
  }

  async onActionDialogClosed(response) {
    let successText = 'Success'!
    this.startDataLoading();
    if (response.isOk) {
      switch (this.action.type) {
        case ('reassign'):
          successText = 'Successfully reassigned!';
          await tasqActionsModule.reassignTasq(this.action);
          break;
        case ('reject'):
          successText = 'Successfully Rejected!';
          await tasqActionsModule.rejectTasq(this.action);
          break;
        case ('wait'):
          successText = 'Successfully added to waitlist!';
          await tasqActionsModule.updateTasqStatus(this.action);
          break;
        case ('add'):
          successText = 'Tasq added successfully!';
          await tasqActionsModule.addTasq(this.action);
          break;
        default:
          break;
      }
    }
    await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    this.action = {};
    this.showActionDialog = false;
    this.stopDataLoading();
    this.$eventBus.$emit(SHOW_ALERT, successText);
  }

  async changeChartTimes(val) {
    this.signalChartTime = val;
    this.chartsLoading = true;

	var promises: any[] = []
	promises.push(tasqSignalsModule.getSignalsForTasq(val))
	promises.push(tasqProductionDataChartModule.getProductionData(val))
	promises.push(tasqProductionDataChartModule.getWellHistory(val))

	

    Promise.all(promises).then(() => {

		this.chartsLoading = false;

		
    }, function (err) {
        // error occurred
		
    });



  }

  async fetchTasq() {
    this.chartsLoading = true;
    this.chartTimes = this.$getConst('CHART_TIMES');
	tasqProductionDataChartModule.resetProductionDataDefermentLabeling()
    if (this.signalChartTime > 45) {
      this.signalChartTime = 14;
    }
    tasqsListModule.setActiveTasq(this.$route.params.id);






	var promises: any[] = [];

	// promises.push(scheduleModule.putTasksParams({
	// 	GanttChartID: this.ganttChartID,
	// 	TaskID: prediction_id,
	// 	ModifyObject: "TargetDate",
	// 	Value: startDateString
	// }))

    
	promises.push(tasqSignalsModule.getSignalsForTasq(this.signalChartTime))
	promises.push(tasqProductionDataChartModule.getProductionData(this.signalChartTime))
	promises.push(tasqProductionDataChartModule.getWellHistory(this.signalChartTime))
	promises.push(defermentLabelingModule.getChartLabelingData(this.signalChartTime))


	if (this.activeTasq != null) {
		tasqsListModule.getTasqEventHistoryList(this.activeTasq?.workflowTasqId)
		workflowModule.getWellHistory(this.activeTasq?.wellName)
		workflowModule.getJobResponse(this.activeTasq?.id)
	}

    // await tasqSignalsModule.getSignalsForTasq(this.signalChartTime);
    // await tasqProductionDataChartModule.getProductionData(this.signalChartTime);
    // await tasqProductionDataChartModule.getWellHistory(this.signalChartTime);
    // await defermentLabelingModule.getChartLabelingData(this.signalChartTime);

    Promise.all(promises).then(() => {
		tasqsListModule.setSignalChartTime(this.signalChartTime);
		if (this.activeTasq?.engineerType === TASQ_OFF_TARGET_TYPE && this.activeTasq?.dateAgoValue > 45) {
		this.chartTimes = [{
			id: 5,
			val: this.activeTasq.dateAgoValue + 30,
			text: 'Job Origin',
			additionalClass: 'chart-time--narrow',
		}, ...this.chartTimes];
		}
		this.chartsLoading = false;

		
    }, function (err) {
        // error occurred
    });



	

	// await tasqsListModule.getTasqEventHistoryList(this.activeTasq?.workflowTasqId)

  }

  @Watch('$route.params.id', {
    immediate: true,
  })
  async onTasqRouteIdChange(id: string) {
    if (id) {
      await this.fetchTasq();
    } else {
      tasqsListModule.setActiveTasq('');
    }
  }
}
